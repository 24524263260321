import http from "./httpService";
import { apiUrl } from "./../config.json";

export function getOrder(orderId) {
    const token = localStorage.getItem("token");
    const apiEndpoint = `${apiUrl}/customer/orderv2/${orderId}`;
    const config = {
        headers: { "x-api-key": token },
    };
    return http.get(apiEndpoint, config);
}

export default {
    getOrder,
};
