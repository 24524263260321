import React from "react";
import Popup from "reactjs-popup";
import renderHTML from "react-render-html";
const Products = ({ products, onChange }) => {
  return (
    <div className="packageMain">
      {products.map((product, index) => (
        <>
          <div
            className={`psDiv ${
              product.type === "BNDL" ? "packageDiv" : ""
            } mb-1`}
            key={product.id}
          >
            <div className="psDivMainHeader">
              <div className="psDivHeader">
                <h3 style={{ color: "#fff", fontWeight: "bold" }}>
                  {product.name}
                </h3>
                <h2 style={{ color: "#fff", fontWeight: "bold" }}>
                  ₹{product.pricing.price}
                </h2>
              </div>
              <div className="psDivDescription">
                {product.description && <p>{product.description}</p>}
              </div>
            </div>
            {product.type === "BNDL" && (
              <ul className="psDivList">
                {product.inclusions.map((inclusion, index) => (
                  <li
                    className={`psDivFeatureList ${index < 3 ? "active" : ""} `}
                    key={inclusion.id}
                  >
                    <img src="img/tick.svg" alt="" /> {inclusion.name}
                  </li>
                ))}
                {product.inclusions.length > 3 && (
                  <li className="showMoreLi">
                    <div className="showMoreDiv">
                      +2 more inclusion <img src="img/downArrow.svg" alt="" />
                    </div>
                  </li>
                )}
              </ul>
            )}
            {product.type === "STND" && (
              <ul className="psDivList">
                {product.features.map((feature, index) => (
                  <li
                    className={`psDivFeatureList ${index < 3 ? "active" : ""} `}
                    key={feature}
                  >
                    <img src="img/tick.svg" alt="" /> {feature}
                  </li>
                ))}
                {product.features.length > 3 && (
                  <li className="showMoreLi">
                    <div className="showMoreDiv">
                      +{product.features.length - 3} more inclusion{" "}
                      <img src="img/downArrow.svg" alt="" />
                    </div>
                  </li>
                )}
              </ul>
            )}
            <div className="psDivFooter">
              <div className="psDivTS">
                <div className="psDivTime">
                  <img src="img/wall-clock.svg" alt="" />
                  <p>2-3 Days</p>
                </div>
                <div id="servicePolicy">
                  <Popup
                    trigger={
                      <a href="#service" data-effect="mfp-move-from-top">
                        Service Policy
                      </a>
                    }
                    modal
                  >
                    {(close) => (
                      <div
                        id="service"
                        className="modal white-popup mfp-with-anim"
                      >
                        <button
                          className="close mfp-close"
                          onClick={close}
                          style={{ color: "#333" }}
                        >
                          &times;
                        </button>
                        <div>
                          {renderHTML(
                            `${
                              product.servicePolicy !== undefined
                                ? product.servicePolicy
                                : "<p>No Policy Found</p>"
                            }`
                          )}
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              <div className="psDivBtn">
                <input
                  type="radio"
                  name="package"
                  value={product.id}
                  onChange={onChange}
                />
                Select
              </div>
            </div>
          </div>
          <>
            {product?.coupons[0] ? (
              <>
                {product?.coupons[0].featured == 1 && (
                  <div className="coupon-div mb-3 flex">
                    <div
                      style={{ width: "50%" }}
                      className="flex flex-center-center"
                    >
                      <span className="coupon-img-div">
                        <img
                          src={`http://makemyhouse.com/assets/themelibv2assets/app_assets/images/new/${product?.coupons[0].image}`}
                        // src={`https://testing.makemyhouse.com/assets/themelibv2assets/app_assets/images/new/${product?.coupons[0].image}`}                       
                           alt=""
                          style={{ objectFit: "cover", height: "100%" }}
                        />
                      </span>
                    </div>
                    <div
                      style={{ width: "50%" }}
                      className="flex flex-center-center"
                    >
                      <span
                        style={{ fontWeight: "600" }}
                        className="product-copon-code"
                      >
                        {" "}
                        <span
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "400",
                            borderBottom: "1px solid",
                          }}
                        >
                          Coupon Code
                        </span>{" "}
                        {product?.coupons[0]?.code}
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </>
        </>
      ))}
    </div>
  );
};

export default Products;
