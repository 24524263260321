import React from "react";
import {
    faUser,
    faEnvelope,
    faUserCircle,
    faHome,
    faBuilding,
    faShoppingCart,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Step10 = ({ onBackClick, onClick }) => {
    // console.log
    return (
        <div id="step10" className="stepHidden">
            <form action="" onSubmit={(e) => onClick("next", e)}>
            <header>
                <div>
                    <div
                        id="topBar"
                        className="d-flex justify-space-between align-center"
                    >
                        <img src="img/MMH Logo_White.svg" alt="" />
                        <div className="reviewDiv d-flex" id="inline-popups">
                            <a
                                href="#review"
                                data-effect="mfp-move-from-top"
                                style={{ color: "white" }}
                            >
                                <img
                                    src="img/edit.svg"
                                    alt=""
                                    className="mr-1"
                                />{" "}
                                Review
                            </a>
                        </div>
                    </div>
                    <div
                                        id="stepDiv"
                                        className="d-flex justify-space-between align-center"
                                    >
                                        <div id="basicInfo" className="active">
                                            <div className="stepIcon text-center">
                                                <FontAwesomeIcon
                                                    icon={faUserCircle}
                                                />
                                                <p>Basic Info</p>
                                            </div>
                                        </div>
                                        <div className="seperator"></div>
                                        <div
                                            id="plotDetails"
                                            className="active"
                                        >
                                            <div className="stepIcon text-center">
                                                <FontAwesomeIcon
                                                    icon={faHome}
                                                />
                                                <p>Plot Details</p>
                                            </div>
                                        </div>
                                        <div className="seperator"></div>
                                        <div id="product">
                                            <div className="stepIcon text-center">
                                                <FontAwesomeIcon
                                                    icon={faBuilding}
                                                />
                                                <p>Product</p>
                                            </div>
                                        </div>
                                        <div className="seperator"></div>
                                        <div id="checkout">
                                            <div className="stepIcon text-center">
                                                <FontAwesomeIcon
                                                    icon={faShoppingCart}
                                                />
                                                <p>Checkout</p>
                                            </div>
                                        </div>
                                    </div>
                    
                </div>
            </header>
            <div id="content">
                <div>
                    <p className="mb-2">
                        <b>Rough Sketch</b>
                    </p>
                    <p className="mb-2">
                        <b>
                            {" "}
                            Draw your imagination on a paper & upload (
                            optional)
                        </b>
                    </p>

                    <input type="file" name="files" id="file-sketch" />
                </div>
            </div>
            <footer>
                <button
                    type="button"
                    className="btn-main btn-back"
                    onClick={() => onBackClick(10)}
                ></button>
                <button
                    type="button"
                    className="btn-skip"
                    data-active="step10"
                    data-next="step11"
                    onClick={(e) => onClick("skip", e)}
                >
                    Skip
                </button>
                <button
                    type="submit"
                    className="btn-main nextstep"
                    data-active="step10"
                    data-next="step11"                    
                >
                    Next Step
                </button>
            </footer>
            </form>
        </div>
    );
};

export default Step10;
