import http from "./httpService";
import { apiUrl } from "./../config.json";

export function getCurrentUser() {
    const token = localStorage.getItem("token");
    const apiEndpoint = `${apiUrl}/customer/me`;
    const config = {
        headers: { "x-api-key": token },
    };
    return http.post(apiEndpoint, {}, config);
}

export default {
    getCurrentUser,
};
