import React, { Component } from 'react';

import AddonPopup from './addonPopup';

class Addons extends Component {
    state = {};
    render() {
        const {addons, selectedProduct} = this.props;
        return (
            <div className="addonBoxContainer">
                {addons.map((addon, index) => (
                <div className="addonBox mb-2" key={addon.id}>
                    <div className="addonBoxInput">
                        <input
                            type="checkbox"
                            name="addons[]"
                            id={`addon${index + 1}`}
                            value={addon.id}
                        />
                        <label htmlFor={`addon${index + 1}`} className="addonLabel">
                            <div className="addonName">{addon.name}</div>
                            <div className="priceAddon">+₹{addon.pricing.price}</div>
                        </label>
                    </div>
                    <AddonPopup selectedProduct={selectedProduct} />
                </div>
                ))}
            </div>
        );
    }
}

export default Addons;
