import React from "react";
import Popup from "reactjs-popup";
import axios from "axios";
import { apiUrl } from "../config.json";
import http from "../services/httpService";
var style1 = {
    ".mfp-wrap.mfp-close-btn-in.mfp-auto-cursor.mfp-zoom-in.mfp-ready": {
        display: "none",
    },
};

const SpecialOffer = ({ coupons, specialOfferShow, onClick, onClose, onShowClick }) => {    

    return (
        <div className="specialOffer">
            <a
                href="#specialOffer"
                data-effect="mfp-zoom-in"
                onClick={() => {
                    onShowClick();
                }}
            >
                Check Offers
            </a>
            <Popup open={specialOfferShow} closeOnDocumentClick onClose={onClose}>
                {/* {(close) => ( */}
                    <div id="specialOffer" className="modal white-popup">
                        <button
                            name="offer-modal-btn-close"
                            className="close mfp-close"
                            onClick={(e)=>{                                
                                onClose(e);
                            }}
                            style={{ color: "#333" }}
                        >
                            &times;
                        </button>
                        <div className="content">
                            {coupons.length > 0 
                                ? coupons.map((offer, index) => (
                                      <div className="specialOfferBox mb-2" key={offer.code}>
                                          <div
                                              className="offerDiv"
                                              onClick={() =>
                                                  onClick(offer.code)
                                              }
                                          >
                                              {offer.code}
                                          </div>

                                          <div className="offerText">
                                              {offer.description}
                                          </div>
                                      </div>
                                  ))
                                : <div className="flex flex-center-center w100" style={{height:"100%"}}>You don not have any offer right now!</div>}
                        </div>
                    </div>
                {/* )} */}
            </Popup>
        </div>
    );
};

export default SpecialOffer;
