import React from "react";
import Popup from "reactjs-popup";

const AddonPopup = ({ selectedProduct }) => {
    return (
        <div className="addonBoxInfo">
            <Popup
                trigger={
                    <a href="#addon1popup" data-effect="mfp-move-from-top">
                        <img src="img/info.svg" alt="" width="100%" />
                    </a>
                }
                modal
            >
                {(close) => (
                    <div id="addon1popup" className="white-popup mfp-with-anim">
                        <button
                            className="close mfp-close"
                            onClick={close}
                            style={{ color: "#333" }}
                        >
                            &times;
                        </button>
                        <div className="content">
                            {selectedProduct.name && (
                                <div className="productName">
                                    <p>{selectedProduct.name}</p>
                                </div>
                            )}

                            <p className="mb-2">
                                <b>Addon Number One</b>
                            </p>
                            <img
                                src="img/addonImage.png"
                                alt=""
                                className="mb-2"
                            />
                            <p>
                                A floor plan is an important document that
                                illustrates what a finished building will look
                                like.
                                <br /> It fits into a larger set of building
                                plans (called either a permit set or a
                                contractor set).
                                <br /> These plans feature cross-section
                                drawings (or elevations), technical drawings
                                that show construction methods, window and door
                                schedules, and a foundation plan.
                                <br /> All these documents provide necessary
                                information for designing, permitting, and
                                executing the building process.
                            </p>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    );
};

export default AddonPopup;
