function populateOrder(data, order) {
    const plot = order.plot;
    data.plotType = plot.type;
    data.floors = plot.floors;
    data.plotDirection = plot.direction;
    data.plotWidth = plot.width;
    data.plotLength = plot.length;
    data.plotBuiltUpArea = plot.builtUpArea;
    if(order.products.length) {
        data.urgentDelivery = order.products[0].instantDelivery;
        data.extraOption = order.products[0].additionalOption;
    }
    
    data.promoCode = order.couponCode || "";
    
    return data;
}

function setPricing(selectedProduct) {
    let price = 0;
    price += selectedProduct.pricing.price;
    price += selectedProduct.pricing.additionalOption;
    price += selectedProduct.pricing.instantDelivery;
    return {price, totalPrice:price};
}

function setPromoDiscount(order) {
    return order.pricing.discount;
}

export default {
    populateOrder,
    setPricing,
    setPromoDiscount,
};