import React from "react";

const Footer = (props) => {
  
    return (
        <footer>
            
            <button type="button" className="btn-main btn-back" onClick={()=>props.onBackClick(props.active)}></button>
         
            <button
                type="submit"
                className="btn-main nextstep"
                data-active={"step" + props.active}
                data-next={"step" + props.next}
                disabled={props.disabled ? props.disabled : false}
            >
                {props.active === 2 ? "Verify and Proceed" : "Next Step"}
            </button>
        </footer>
    );
};

export default Footer;
