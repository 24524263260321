import React from "react";

const FloorRadio = ({checked, imgSrc, label, name, value, onChange}) => {
    return (
        <label className="radio_btns" htmlFor={label}>
            <img src={imgSrc} />
            
                <input
                    value={value}
                    type="radio"
                    className="radios"
                    name={name}
                    id={label}
                    checked={checked}
                    onChange={onChange}
                />
                <span>{label}</span>
        </label>
    );
};

export default FloorRadio;
