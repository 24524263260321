import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import queryString from "query-string";

import { apiUrl, checkOutUrl } from "./config.json";
import http from "./services/httpService";
import auth from "./services/authService";
import orderService from "./services/orderService";

import orderUtility from "./utils/orderUtility";

import MyRadio from "./components/common/myRadio";
import FloorRadio from "./components/common/floorRadio";

import Addons from "./components/addons";
import Products from "./components/products";
import Projects from "./components/projects";
import IntermediateStep from "./components/IntermediateStep/intermediateStep";
import Review from "./components/review";
import SpecialOffer from "./components/specialOffer";
import Step10 from "./components/step10";
import Footer from "./components/footer";
import logo from "../src/assets/logo-300.png";
import loader from "./assets/loader.gif";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { withRouter } from "react-router-dom";
import { Cookies } from "react-cookie";

import {
  faUser,
  faEnvelope,
  faUserCircle,
  faHome,
  faBuilding,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./master.scss";
import { Slider } from "antd";
import "antd/dist/antd.css";
const cookies = new Cookies();

class App extends Component {
  state = {
    tokenCookie: cookies.get("mmh_common_session"),
    countries: [],
    coupons: [],
    token: null,
    orderId: null,
    order: {},
    products: [],
    projects: [],
    relatedDesigns: [],
    selectedAddons: [],
    selectedProducts: [],
    addons: [],
    userDetails: {
      state: "",
      locationaddress: "",
    },
    price: 0,
    promoDiscount: null,
    totalPrice: 0,
    isConfirmed: false,
    isLoading: false,
    data: {
      country: "IN",
      mobile: "",
      isd: "",
      email: "",
      firstName: "",
      floors: "1",
      lastName: localStorage?.userDetails?.lastName,
      plotBuiltUpArea: "",
      plotDirection: "N",
      plotWidth: "",
      plotLength: "",
      plotType: "RESD",
      postalcode: "",
      promoCode: "",
      state: "",
      urgentDelivery: false,
      extraOption: false,
      right_neighour: "Neighbour",
      back_neighour: "Neighbour",
      left_neighour: "Neighbour",
    },
    specialOfferShow: false,
    states: [],
    draftOrder: [],
    budget: 5000000,
    singleProduct: false,
    isGst: "NO",
    gstNo: "",
    businessName: "",
  };

  minimumBuiltUpArea = null;

  utm_source = null;
  utm_medium = null;
  utm_campaign = null;

  apiEndpoint = `${apiUrl}/customer`;
  headers = null;

  marks = {
    1000000: "10L",
    3000000: "30L",
    5000000: "50L",
    7000000: "70L",
    10000000: "1Cr+",
  };
   getCookie =(cookie_name)=>{
    var b = document.cookie.match(
      "(^|;)\\s*" + cookie_name + "\\s*=\\s*([^;]+)"
    );
    return b ? b.pop() : "";
  };

  async componentDidMount() {
    // 1c34358e674a2bfeba1e72115ad579bd

    window.apiUrl = apiUrl;

    const result = queryString.parse(window.location.search);

    let token = null;
    let userDetails = null;
    let orderId = null;

    let data = { ...this.state.data };
    let price = 0;
    let promoDiscount = null;
    let selectedProducts = [];
    let totalPrice = 0;
    //  console.log('cookieeee',this.state.tokenCookie,' cookiem2-',this.getCookie("mmh_common_session"))
    if (this.getCookie("mmh_common_session")) {
      let tokenKey = await axios
        .get(
          `${apiUrl}/public/encryption/decode/?token=${this.getCookie("mmh_common_session")}`
        )
        .catch((err) => {
          console.log("errr-rrrr--1", err);
          window.location.replace(`${apiUrl}/login?back=${window.location.href}&appcode=CHKT&leadsource=checkout`);
        });
      token = tokenKey.data.key;
      localStorage.setItem("token", tokenKey.data.key);
      const response = await auth.getCurrentUser().catch((err) => {
        console.log("errr---2", err);
        window.location.replace(`${apiUrl}/login?back=${window.location.href}&appcode=CHKT&leadsource=checkout`);
      });
      userDetails = response ? response?.data : null;
    } else if (result.token) {
      token = result.token;
      localStorage.setItem("token", result.token);
      const response = await auth.getCurrentUser().catch((err) => {
        console.log("errr3", err);
        window.location.replace(`${apiUrl}/login?back=${window.location.href}&appcode=CHKT&leadsource=checkout`);
      });
      userDetails = response?.data;
    } else if (localStorage.getItem("token")) {
      
      token = localStorage.getItem("token");
      const response = await auth.getCurrentUser().catch((err) => {
        console.log("errr4", err);
        window.location.replace(`${apiUrl}/login?back=${window.location.href}&appcode=CHKT&leadsource=checkout`);
      });
      userDetails = response?.data;
      //  userDetails = JSON.parse(localStorage.getItem("userDetails"));
    } else if (
      (!this.getCookie("mmh_common_session") || !cookies.get("mmh_common_session")) &&
      !localStorage.getItem("token")
    ) {
      console.log(this.state.tokenCookie, cookies.get("mmh_common_session"));
      //  this.props.history.replace(`${apiUrl}/login?back=${window.location.href}&appcode=CHKT&leadsource=checkout`);
      console.log("error5",this.getCookie("mmh_common_session"))
      window.location.replace(`${apiUrl}/login?back=${window.location.href}&appcode=CHKT&leadsource=checkout`);
       ;

    }

    if (token) {
      this.headers = {
        headers: { "x-api-key": token },
      };
    }

    this.utm_source = result.utm_source;
    this.utm_medium = result.utm_medium;
    this.utm_campaign = result.utm_campaign;

    if (userDetails !== null) {
      data.firstName = userDetails?.firstname;
      data.lastName = userDetails?.lastName;
      data.email = userDetails?.email;
      data.country = userDetails?.country;
      data.state = userDetails?.state;
      data.postalcode = userDetails?.postalcode;
    }

    /* Check if order code exist */
    if (result?.ordercode) {
      orderId = result.ordercode;
    }

    if (userDetails !== null && orderId !== null) {
      let nextstep = 9;
      try {
        const response = await orderService.getOrder(result.ordercode);
        const order = response.data;
        data = orderUtility.populateOrder(data, order);

        if (order.products.length) {
          selectedProducts = order.products;
          nextstep = 16;
        }

        if (order.couponCode) {
          promoDiscount = orderUtility.setPromoDiscount(order);
        }

        price = order?.pricing?.baseAmount;
        totalPrice = order?.pricing?.baseAmount - order?.pricing?.discount;

        this.setState({
          data,
          isLoading: false,
          order,
          orderId,
          price,
          promoDiscount,
          selectedProducts,
          totalPrice,
          token,
          userDetails,
        });

        $("#" + "step9" + " .btn-back").attr("disabled", "disabled");

        // $("#" + "step1" + "").slideUp();
        $("#" + "step" + nextstep).slideDown();
      } catch (ex) {
        console.log(ex);
      }
    } else if (userDetails !== null && orderId === null) {
      //  $("#" + "step1" + "").slideUp();
      $("#" + "step3" + "").slideDown();
      this.setState({ data, isLoading: false, token, userDetails });
    } else if (userDetails === null && orderId !== null) {
      this.setState({ data, isLoading: false, orderId, token });
    } else {
      this.setState({ isLoading: false });
    }

    const response = await axios.get(
      `${apiUrl}/public/geolist?limit=200&offset=0`
    );
    this.setState({ countries: response.data.countries });
    await this.getStates();

    window.onbeforeunload = function () {
      return "Your work will be lost.";
    };
  }

  calculateBudget() {
    let budget = 0;
    switch ($("#ex22").val()) {
      case "1":
        budget = 1000000;
        break;
      case "2":
        budget = 3000000;
        break;
      case "3":
        budget = 5000000;
        break;
      case "4":
        budget = 7000000;
        break;
      case "5":
        budget = 10000000;
        break;

      default:
        break;
    }
    return budget;
  }

  calculatePrice() {
    const { selectedProducts, selectedAddons, data } = this.state;
    const { urgentDelivery, extraOption } = data;

    let price = 0;
    price += selectedProducts[0].pricing.price;
    if (urgentDelivery) price += selectedProducts[0].pricing.immediate_delivery;
    if (extraOption) price += selectedProducts[0].pricing.extra_option;
    let addonPrice = 0;
    selectedAddons.forEach((addon) => {
      addonPrice += addon.pricing.price;
    });
    price += addonPrice;
    return price;
  }

  destroyOrder(data) {
    const st = {
      order: {},
      products: [],
      projects: [],
      relatedDesigns: [],
      selectedAddons: [],
      selectedProducts: [],
      price: 0,
      promoDiscount: null,
      totalPrice: 0,
    };
    this.setState(st);
  }
  handleBackClick16 = () => {
    const prev = 16 - 1;
    $("#step" + "16" + "").slideUp();
    $("#step" + prev + "").slideDown();
    return;
  };

  handleBackClick = async (active) => {
    if (
      active === 14 &&
      this.state.projects.length === 0 &&
      this.state.singleProduct
    ) {
      $("#step" + active + "").slideUp();
      $("#" + "step12" + "").slideDown();
      $(`.nextstep[data-active="step12"]`).attr("disabled", "disabled");
      $(".overlay").addClass("show");
      setTimeout(() => {
        $(".overlay").removeClass("show").addClass("hide");
      }, 5000);

      const { plot } = this.state.order;
      const response = await http.get(
        `${apiUrl}/public/projects?image_category=Exterior Design&width=${plot.width}&length=${plot.length}&page=1`
      );
      if (response.data.msg) {
        alert(response.data.msg);
        $("#" + "step12" + "").slideUp();
        $("#" + "step13" + "").slideDown();
        return;
      }

      const projects = response.data;
      this.setState({ projects });

      return;
    } else if (this.state.singleProduct) {
      $("#" + "step15" + "").slideUp();
      $("#" + "step14" + "").slideUp();
      $("#" + "step13" + "").slideDown();
      return;
    }

    console.log(this.state);
    const prev = parseInt(active) - 1;
    $("#step" + active + "").slideUp();
    $("#step" + prev + "").slideDown();
    return;
  };

  handleBackClick24 = (active) => {
    console.log("asd", "hello");
    const prev = parseInt(24) - 1;
    $("#step" + 24 + "").slideUp();
    $("#step" + prev + "").slideDown();
    return;
  };
  handleBackClick21 = (active) => {
    const prev = parseInt(active) - 14;
    $("#step" + active + "").slideUp();
    $("#step" + prev + "").slideDown();
    return;
  };

  handleBackClick1 = (active) => {
    localStorage.removeItem("token");
    const prev = parseInt(active) - 2;
    $("#step" + active + "").slideUp();
    $("#step" + prev + "").slideDown();
    return;
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleCheckboxChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.checked ? true : false;
    this.setState({ data });
  };

  handleDecrement(id) {
    let state = JSON.parse(JSON.stringify(this.state));
    let value = parseInt($(`#${id}`).val(), 10);
    value = isNaN(value) ? 0 : value;
    if (value == "0") {
    } else {
      value--;
      $(`#${id}`).val(value);
      state[`${id}`] = value;
    }
    console.log(state);
    this.setState(state);
  }

  handleIncrement = (id) => {
    let state = JSON.parse(JSON.stringify(this.state));
    let value = parseInt($(`#${id}`).val(), 10);
    value = isNaN(value) ? 0 : value;
    value++;
    state[`${id}`] = value;
    console.log(state);
    this.setState(state);
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handlePromoClick(e.target.value);
    }
  };

  handleProductChange = async (e) => {
    const productId = e.target.value;
    const selectedProducts = this.state.products.filter(
      (p) => p.id == productId
    );
    if (
      selectedProducts[0]?.coupons &&
      selectedProducts[0]?.coupons[0]?.featured == 1
    ) {
      localStorage.setItem(
        "couponsDetail",
        JSON.stringify(selectedProducts[0]?.coupons)
      );
    } else {
      localStorage.removeItem("couponsDetail");
    }
    if (selectedProducts[0].addons.length > 0) {
      $("#" + "step13" + "").slideUp();
      $("#" + "step14" + "").slideDown();
      this.setState({
        selectedProducts: selectedProducts,
        singleProduct: false,
        addons: selectedProducts[0].addons,
      });
    } else {
      this.setState({ isLoading: true });
      let state = JSON.parse(JSON.stringify(this.state));

      let items = [];
      items.push({
        id: selectedProducts[0].id,
        instantDelivery: state.data.urgentDelivery,
        additionalOption: state.data.extraOption,
      });

      try {
        const { data: order } = await axios.post(
          `${this.apiEndpoint}/orderv2/products/${state.order.id}?source=checkout`,
          { products: items },
          this.headers
        );
        state.order = order;

        state.selectedProducts = order.products;

        state.price = order.pricing.baseAmount;
        state.totalPrice = order.pricing.baseAmount - order.pricing.discount;
        state.isLoading = false;
      } catch (ex) {
        state.isLoading = false;
      }
      state.singleProduct = true;
      console.log(">>>>>>>>>>>>> handle log", state);
      this.setState(state);
      $("#" + "step13" + "").slideUp();
      $("#" + "step15" + "").slideDown();
    }
  };

  handlePromoClick = async (promoCode) => {
    const specialOfferShow = false;
    this.setState({ isLoading: true, specialOfferShow });
    const apiEndpoint = `${apiUrl}/customer`;
    const dt = {
      promocode: promoCode,
    };
    const headers = {
      headers: {
        "x-api-key": this.state.token,
        "Content-Type": "application/json",
      },
    };
    try {
      const { data: order } = await axios.post(
        `${apiEndpoint}/orderv2/coupon/${this.state.order.id}?source=checkout`,
        dt,
        headers
      );

      let promoDiscount = this.state.price - order.pricing.netAmount;
      let totalPrice = this.state.price - promoDiscount;
      console.log(
        "promoCode",
        promoCode,
        "promoDiscount",
        promoDiscount,
        "totalPrice",
        totalPrice,
        "order",
        order,
        "data",
        data
      );

      const data = { ...this.state.data };
      data.promoCode = promoCode;

      this.setState({
        isLoading: false,
        order,
        promoDiscount,
        totalPrice,
        data,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        alert(ex.response.data.couponCode);
      }
      this.setState({ isLoading: false });
    }
  };

  handlePromoRemoveClick = async () => {
    this.setState({ isLoading: true });

    const { data: order } = await axios.post(
      `${this.apiEndpoint}/orderv2/coupon/${this.state.order.id}?remove=TRUE&source=checkout`,
      {},
      this.headers
    );

    const data = { ...this.state.data };
    data.promoCode = "";

    const promoDiscount = null;

    let price = order.pricing.baseAmount;
    let totalPrice = order.pricing.baseAmount - order.pricing.discount;

    this.setState({
      isLoading: false,
      order,
      price,
      promoDiscount,
      totalPrice,
      data,
    });
  };

  handleIntermediateStep = async (event) => {
    if (event === "new") {
      $(`#inter-step`).slideUp();
      $(`#step4`).slideDown();
    } else {
      window.onbeforeunload = null;
      window.location.href = queryString.parse(window.location.search).token ?`${window.location.href}&ordercode=${this.state.draftOrder.orders[0].id}`:`${window.location.href}?ordercode=${this.state.draftOrder.orders[0].id}`;
    }
  };

  handleStep3 = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const data = {
      firstName: this.state.data.firstName,
      lastName: this.state.data.lastName,
      email: this.state.data.email,
    };
    let oldData = [];
    try {
      oldData = JSON.parse(
        JSON.stringify(
          await http.get(
            `${apiUrl}/customer/orderv2/?limit=1&orderStatus=1&parent=1`,
            this.headers
          )
        )
      );
      this.setState({ draftOrder: oldData.data });
    } catch (ex) {
      if (ex.response) {
        confirmAlert({
          title: "Session Expired ",
          message: "Please Login Again",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                localStorage.removeItem("token");
                // console.log("get",active)
                // const prev = parseInt(active) - 2;
                $("#step" + 3 + "").slideUp();
                $("#step" + 1 + "").slideDown();
                window.location.reload();
                return;
              },
            },
          ],
        });
      }
    }

    try {
      const { data: userDetails } = await http.post(
        `${this.apiEndpoint}/me`,
        data,
        this.headers
      );
      const dt = { ...this.state.data };
      dt.locationaddress = userDetails.locationaddress;
      dt.city = userDetails.city;
      dt.state = userDetails.state;
      dt.postalcode = userDetails.postalcode;
      this.setState({ data: dt, isLoading: false, userDetails });
      if (!Array.isArray(oldData.data)) {
        if (oldData.data.orders.length > 0) {
          $("#" + "step3" + "").slideUp();
          $(`#inter-step`).slideDown();
        } else {
          $("#" + "step3" + "").slideUp();
          $("#" + "step4" + "").slideDown();
        }
      } else {
        $("#" + "step3" + "").slideUp();
        $("#" + "step4" + "").slideDown();
      }

      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    } catch (ex) {
      if (ex.response) {
        // alert(ex.response
        if (!("lastName" in ex.response.data)) {
          confirmAlert({
            title: "Session Expired ",
            message: "Please Login Again",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  localStorage.removeItem("token");
                  // console.log("get",active)
                  // const prev = parseInt(active) - 2;
                  $("#step" + 3 + "").slideUp();
                  $("#step" + 1 + "").slideDown();
                  window.location.reload();
                  return;
                },
              },
            ],
          });
        }
      }
    }

    this.setState({ isLoading: false });
  };

  handleStep4 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    const { plot } = this.state.order;
    if (!plot || plot.type === data.plotType || plot.floors === data.floors) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    $("#" + "step4" + "").slideUp();
    $("#" + "step5" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep5 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data.plotDirection = $('input[name="plotDirection"]:checked').val();

    const { plot } = this.state.order;
    if (!plot || plot.direction === data.plotDirection) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    $("#" + "step5" + "").slideUp();
    $("#" + "step6" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep6 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };

    const { plot } = this.state.order;
    if (!plot || plot.width === data.plotWidth) {
    } else {
      this.destroyOrder(data);
    }

    $("#" + "step6" + "").slideUp();
    $("#" + "step7" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep7 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };

    data.plotBuiltUpArea = data.floors * data.plotWidth * data.plotLength;

    const { plot } = this.state.order;
    if (!plot || plot.length === data.plotLength) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    this.minimumBuiltUpArea = Math.floor(
      data.plotBuiltUpArea - this.percentage(data.plotBuiltUpArea, 30)
    );

    $("#" + "step7" + "").slideUp();
    $("#" + "step21" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep21 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data.inRight = $('input[name="rightDirection"]:checked').val();
    console.log("test", data);
    const { plot } = this.state.order;
    if (!plot || plot.inRight === data.inRight) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    $(`.nextstep[data-active="step21"]`).removeAttr("disabled");
    $("#" + "step21" + "").slideUp();
    $("#" + "step22" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep22 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data.inBack = $('input[name="backDirection"]:checked').val();
    console.log("test", data);
    const { plot } = this.state.order;
    if (!plot || plot.inBack === data.inBack) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    $(`.nextstep[data-active="step22"]`).removeAttr("disabled");
    $("#" + "step22" + "").slideUp();
    $("#" + "step23" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep23 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data.inLeft = $('input[name="leftDirection"]:checked').val();
    console.log("test", data);
    const { plot } = this.state.order;
    if (!plot || plot.inLeft === data.inLeft) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    $(`.nextstep[data-active="step23"]`).removeAttr("disabled");
    $("#" + "step23" + "").slideUp();
    $("#" + "step24" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep24 = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };

    data.plotBuiltUpArea = data.floors * data.plotWidth * data.plotLength;

    const { plot } = this.state.order;
    if (!plot || plot.length === data.plotLength) {
      this.setState({ data });
    } else {
      this.destroyOrder(data);
    }

    $(`.nextstep[data-active="step24"]`).removeAttr("disabled");
    $("#" + "step24" + "").slideUp();
    $("#" + "step8" + "").slideDown();

    localStorage.setItem("data", JSON.stringify(data));
  };

  handleStep8 = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const data = { ...this.state.data };

    const { plot } = this.state.order;
    if (!plot || plot.builtUpArea === data.plotBuiltUpArea) {
      let dt = {
        length: data.plotLength,
        width: data.plotWidth,
        floors: data.floors,
        direction: data.plotDirection,
        type: data.plotType,
        builtUpArea: data.plotBuiltUpArea,
        inBack: data.inBack,
        inRight: data.inRight,
        inLeft: data.inLeft,
      };

      dt =
        this.state?.isGst === "Yes"
          ? {
              ...dt,
              isGst: this.state?.isGst,
              gstNo: this.state?.gstNo,
              businessName: this.state?.businessName,
            }
          : { ...dt };

      const headers = {
        headers: { "x-api-key": this.state.token },
      };

      try {
        const { data: order } = await http.post(
          `${this.apiEndpoint}/orderv2?source=checkout`,
          dt,
          this.headers
        );
        this.setState({ data, order });

        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("order", JSON.stringify(order));
      } catch (ex) {}
    } else {
      this.destroyOrder(data);
    }

    this.setState({ isLoading: false });
    $("#" + "step8" + "").slideUp();
    $("#" + "step9" + "").slideDown();
  };

  handleStep9 = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const data = { ...this.state.data };
    data.budget = this.state.budget;
    data.numberOfRooms = $("#roomqunatity").val();
    data.adults = $("#adults").val();
    data.children = $("#kids").val();

    const dt = {
      budget: data.budget,
      numberOfRooms: data.numberOfRooms,
      adults: data.adults,
      children: data.children,
    };

    try {
      const { data: order } = await http.post(
        `${this.apiEndpoint}/orderv2/details/${this.state.order.id}?attribute=info&source=checkout`,
        dt,
        this.headers
      );
      this.setState({ data, order });

      localStorage.setItem("data", JSON.stringify(data));
      localStorage.setItem("order", JSON.stringify(order));
    } catch (ex) {
      console.log("Something went wrong.");
    }

    this.setState({ isLoading: false });
    $("#" + "step9" + "").slideUp();
    $("#" + "step10" + "").slideDown();
  };

  handleStep10 = async (type, e) => {
    e.preventDefault();
    console.log("calling");
    if (type === "skip") {
    } else {
      this.setState({ isLoading: true });

      const { order } = this.state;
      try {
        await http.post(
          `${this.apiEndpoint}/orderv2/details/${order.id}?attribute=sketch&source=checkout`,
          { sketch: window.sketch },
          this.headers
        );
      } catch (ex) {
        console.log("ex", ex);
      }
      this.setState({ isLoading: false });
    }

    $("#" + "step10" + "").slideUp();
    $("#" + "step11" + "").slideDown();
    return;
  };

  handleStep11 = async (type, e) => {
    e.preventDefault();
    if (type === "skip") {
    } else {
      this.setState({ isLoading: true });
      const { order } = this.state;
      try {
        await http.post(
          `${this.apiEndpoint}/orderv2/details/${order.id}?attribute=siteImage&source=checkout`,
          { design: window.design },
          this.headers
        );
      } catch (ex) {}
      this.setState({ isLoading: false });
    }

    $("#" + "step11" + "").slideUp();
    $("#" + "step12" + "").slideDown();
    $(`.nextstep[data-active="step12"]`).attr("disabled", "disabled");

    $(".overlay").addClass("show");
    setTimeout(() => {
      $(".overlay").removeClass("show").addClass("hide");
    }, 5000);

    const { plot } = this.state.order;
    const response = await http.get(
      `${apiUrl}/public/projects?image_category=Exterior Design&width=${plot.width}&length=${plot.length}&page=1`
    );
    if (response.data.msg) {
      return;
    }
    const projects = response.data;
    this.setState({ projects });

    localStorage.setItem("projects", JSON.stringify(projects));
  };

  handleStep12 = async (type, e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { order } = this.state;
    let relatedDesigns = [];

    if (type === "skip") {
    } else {
      const ids = [];
      $('input[name="designCode[]"]:checked').each(function () {
        ids.push($(this).val());
      });
      relatedDesigns = ids;

      const response = await axios.post(
        `${this.apiEndpoint}/orderv2/details/${order.id}?attribute=relatedDesigns&source=checkout`,
        { relatedDesigns: ids },
        this.headers
      );
    }

    // Get products listing
    const { data: products } = await http.get(
      `${apiUrl}/public/productsv2/${order.id}?couponCode=1`
    );
    this.setState({ isLoading: false, products, relatedDesigns });

    $("#" + "step12" + "").slideUp();
    $("#" + "step13" + "").slideDown();
  };

  handleStep14 = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { data } = this.state;
    let selectedProducts = this.state.selectedProducts;
    const { urgentDelivery, extraOption } = data;

    const ids = [];
    $(`input[name="addons[]"]:checked`).each(function () {
      ids.push(parseInt($(this).val()));
    });
    const selectedAddons = this.state.addons.filter((addon) => {
      return ids.includes(addon.id);
    });

    let items = [];

    items.push({
      id: selectedProducts[0].id,
      instantDelivery: urgentDelivery,
      additionalOption: extraOption,
    });

    selectedAddons.forEach((addon) => {
      items.push({
        id: addon.id,
        instantDelivery: false,
        additionalOption: false,
      });
    });

    try {
      const { data: order } = await axios.post(
        `${this.apiEndpoint}/orderv2/products/${this.state.order.id}?source=checkout`,
        { products: items },
        this.headers
      );

      selectedProducts = order.products;

      const price = order.pricing.baseAmount;
      const totalPrice = order.pricing.baseAmount - order.pricing.discount;

      const response = await axios.get(
        `${apiUrl}/public/geolist?limit=200&offset=0`
      );
      const countries = response.data.countries;

      this.setState({
        countries,
        order,
        price,
        selectedAddons,
        selectedProducts,
        totalPrice,
      });

      await this.getStates();
    } catch (ex) {}

    this.setState({ isLoading: false });

    $("#" + "step14" + "").slideUp();
    $("#" + "step15" + "").slideDown();
  };

  handleStep15 = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const data = {
      firstName: this.state.data.firstName,
      email: this.state.data.email,
      address: this.state.data.locationaddress,
      country: this.state.data.country,

      city: this.state.data.city,

      state: this.state.data.state,
      postalcode: this.state.data.postalcode,
    };

    const { data: userDetails } = await http.post(
      `${this.apiEndpoint}/me`,
      data,
      this.headers
    );

    this.setState({
      isLoading: false,
      userDetails,
    });

    $("#" + "step15" + "").slideUp();
    $("#" + "step16" + "").slideDown();

    if (localStorage.getItem("couponsDetail") && this.state.selectedProducts) {
      this.handlePromoClick(
        JSON.parse(localStorage.getItem("couponsDetail"))[0]?.code
      );
    }
  };

  handleStep16 = async (e) => {
    e.preventDefault();
    $(".btn-pay").attr("disabled", "disabled");

    let data = {};
    data = this.setUtmProperties(data);

    const response = await axios.post(
      `${this.apiEndpoint}/orderv2/create/${this.state.order.id}?source=checkout`,
      data,
      this.headers
    );

    window.onbeforeunload = null;
    // window.location.href = `${response.data.contractUrl}${localStorage.getItem("token")}`;
    window.location.href = `${response.data.paymentLink}${localStorage.getItem("token")}`;
  };

  handleYes = () => {
    this.setState({ isConfirmed: true, checked: true, unchecked: false });
  };
  handleNo = () => {
    this.setState({ isConfirmed: false, unchecked: true, checked: false });
  };

  getCoupons = async () => {
    this.setState({ isLoading: true });
    const url = `${apiUrl}/public/coupon?orderID=${this.state.order.id}`;

    let coupons = [];
    try {
      const response = await http.get(url);
      coupons = response.data;
    } catch (ex) {}
    this.setState({ coupons, isLoading: false, specialOfferShow: true });
  };

  getStates = async (countryCode) => {
    const country = countryCode || this.state.data.country;
    const res = await http.get(
      apiUrl + "/public/geolist/states/" + country + "?limit=100&offset=0"
    );

    const states = res.data.states;

    this.setState({ states });
  };

  percentage = (num, per) => {
    return (num / 100) * per;
  };

  setUtmProperties(data) {
    if (this.utm_source != null) data.utm_source = this.utm_source;
    if (this.utm_medium != null) data.utm_medium = this.utm_medium;
    if (this.utm_campaign != null) data.utm_campaign = this.utm_campaign;
    return data;
  }

  render() {
    const { data, selectedProducts } = this.state;
    const selectedProduct =
      selectedProducts?.length > 0 ? selectedProducts[0] : {};
    console.log(selectedProduct);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>", this.state);

    return (
      <div className="App" id="makeMyHouseForm">
        {this.state.isLoading && (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={loader} />
          </div>
        )}
        <main id="inner" className={this.state.isLoading ? "d-none" : ""}>
          <div className="overlay" id="box1">
            <div
              className="spinner-border spinner-border-sm _text-primary spinner1 "
              id="testout"
            ></div>
            <h3>
              Generating some front designs for you to choice as a reference
            </h3>
          </div>

          {/* First name last name 2 fields */}
          <div id="step3" className="stepHidden">
            <form action="" onSubmit={this.handleStep3}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <h2
                    style={{
                      marginBottom: 15,
                      textAlign: "center",
                    }}
                  >
                    User Information
                  </h2>
                  <div className="inputWithIcon mb-3">
                    <FontAwesomeIcon icon={faUser} />
                    <label className="input">
                      <span className="input-label">First Name</span>
                      <input
                        className="input-field"
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={this.state.data.firstName}
                        onChange={this.handleChange}
                        required="required"
                      />
                    </label>
                  </div>
                  <div className="inputWithIcon mb-3">
                    <FontAwesomeIcon icon={faUser} />
                    <label className="input">
                      <span className="input-label">Last Name</span>
                      <input
                        className="input-field"
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={
                          Object.keys(this.state.data).includes("lastName") &&
                          this.state.data.lastName !== ""
                            ? this.state.data.lastName
                            : Object.keys(this.state.data).includes(
                                "lastname"
                              ) && this.state.data.lastname !== ""
                            ? this.state.lastname
                            : localStorage.userDetails.lastName
                        }
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="inputWithIcon">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <label className="input">
                      <span className="input-label">Email Address</span>
                      <input
                        className="input-field"
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        value={this.state.data.email}
                        onChange={this.handleChange}
                        required="required"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <Footer active="3" next="4" onBackClick={this.handleBackClick1} />
            </form>
          </div>
          {/* Intermediate Step  */}
          <div id="inter-step" className="stepHidden">
            {
              <IntermediateStep
                handleIntermediateStep={this.handleIntermediateStep}
                data={this.state.draftOrder?.orders}
              />
            }
          </div>
          {/* Intermediate Step  */}
          <div id="step4" className="stepHidden">
            <form action="" onSubmit={this.handleStep4}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <div className="plotDetaisl text-left">
                    <p className="mb-2">
                      <b>Property Type</b>
                    </p>
                    <div className="mb-1"></div>
                    <MyRadio
                      checked={data.plotType === "RESD"}
                      id="residential"
                      imgSrc="img/residential.svg"
                      label="Residential"
                      name="plotType"
                      value="RESD"
                      onChange={this.handleChange}
                    />
                    <MyRadio
                      checked={data.plotType === "COMM"}
                      id="commercial"
                      imgSrc="img/commercial.svg"
                      label="Commercial/Apartments"
                      name="plotType"
                      value="COMM"
                      onChange={this.handleChange}
                    />
                    <p className="mb-2 mt-4">
                      <b>Number of Floors</b>
                    </p>
                    <div className="numberOfFloors">
                      {/* <input
                                                id="ex21"
                                                type="text"
                                                data-provide="slider"
                                                data-slider-ticks="[1, 2, 3, 4, 5]"
                                                data-slider-ticks-labels='["1", "2", "3", "4", "5+"]'
                                                data-slider-min="1"
                                                data-slider-max="5"
                                                data-slider-step="1"
                                                data-slider-value="3"
                                                data-slider-tooltip="hide"
                                            /> */}
                      <FloorRadio
                        checked={data.floors == "1"}
                        imgSrc="img/1-floor.png"
                        label="1 floor"
                        name="floors"
                        value="1"
                        onChange={this.handleChange}
                      />
                      <FloorRadio
                        checked={data.floors == "2"}
                        imgSrc="img/2-floor.png"
                        label="2 floor"
                        name="floors"
                        value="2"
                        onChange={this.handleChange}
                      />
                      <FloorRadio
                        checked={data.floors == "3"}
                        imgSrc="img/3-floor.png"
                        label="3 floor"
                        name="floors"
                        value="3"
                        onChange={this.handleChange}
                      />
                      <FloorRadio
                        checked={data.floors == "4"}
                        imgSrc="img/4-floor.png"
                        label="4 floor"
                        name="floors"
                        value="4"
                        onChange={this.handleChange}
                      />
                      <FloorRadio
                        checked={data.floors == "5"}
                        imgSrc="img/5-floor.png"
                        label="5 floor"
                        name="floors"
                        value="5"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Footer active="4" next="5" onBackClick={this.handleBackClick} />
            </form>
          </div>
          <div id="step5" className="stepHidden">
            <form action="" onSubmit={this.handleStep5}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <div className="compass mb-4">
                    <img
                      src="img/compass.svg"
                      alt=""
                      className="compassImg comapssImgGlobal"
                    />
                    <img src="img/triangle.svg" alt="" className="triangle" />
                  </div>
                  <p className="mb-2">
                    <b>Plot Direction</b>
                  </p>
                  <div className="radioinline d-flex justify-space-between">
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="north"
                        name="plotDirection"
                        value="N"
                        checked={
                          data.plotDirection == "N" ||
                          data.plotDirection == "NN"
                        }
                        onChange={this.handleChange}
                      />
                      <label htmlFor="north">N</label>
                    </div>
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="east"
                        name="plotDirection"
                        value="E"
                        checked={
                          data.plotDirection == "E" ||
                          data.plotDirection == "EE"
                        }
                        onChange={this.handleChange}
                      />
                      <label htmlFor="east">E</label>
                    </div>
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="west"
                        name="plotDirection"
                        value="W"
                        checked={
                          data.plotDirection == "W" ||
                          data.plotDirection == "WW"
                        }
                        onChange={this.handleChange}
                      />
                      <label htmlFor="west">W</label>
                    </div>
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="south"
                        name="plotDirection"
                        value="S"
                        checked={
                          data.plotDirection == "S" ||
                          data.plotDirection == "SS"
                        }
                        onChange={this.handleChange}
                      />
                      <label htmlFor="south">S</label>
                    </div>
                  </div>
                  <div className="radioinline d-flex justify-space-between">
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="northeast"
                        name="plotDirection"
                        value="NE"
                        checked={data.plotDirection == "NE"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="northeast">NE</label>
                    </div>
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="southeast"
                        name="plotDirection"
                        value="SE"
                        checked={data.plotDirection == "SE"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="southeast">SE</label>
                    </div>
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="southwest"
                        name="plotDirection"
                        value="SW"
                        checked={data.plotDirection == "SW"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="southwest">SW</label>
                    </div>
                    <div className="radioBox mb-1">
                      <input
                        type="radio"
                        id="northwest"
                        name="plotDirection"
                        value="NW"
                        checked={data.plotDirection == "NW"}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="northwest">NW</label>
                    </div>
                  </div>
                </div>
              </div>

              <Footer active="5" next="6" onBackClick={this.handleBackClick} />
            </form>
          </div>
          {/* Required */}
          <div id="step6" className="stepHidden">
            <form action="" onSubmit={this.handleStep6}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <div className="plot_floor_plan ">
                    <div className="floorPlan">
                      {/* <img src={data.plotType === 'RESD' ? "assets/ResidentialHouseWidth.gif" : "assets/CommercialWidth.gif"} alt="" /> */}
                      <img
                        src={
                          data.plotType === "RESD"
                            ? "img/width_gif.gif"
                            : "img/width_gif.gif"
                          // : "assets/CommercialWidth.gif"
                        }
                        width="230px"
                        height="230px"
                        alt=""
                      />
                    </div>
                    <div
                      className="compassUpdatedDiv "
                      style={{ left: "-20px", top: "50px" }}
                    >
                      <img
                        src="img/compass.svg"
                        alt=""
                        width="100px"
                        height="100px"
                        className="compassUpdated comapssImgGlobal"
                      />
                      <img src="img/triangle.svg" alt="" className="triangle" />
                    </div>
                  </div>
                  <div className="roadDiv mt-2">
                    <img
                      src="img/Group 3178.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="formInput mt-2">
                    <label className="input">
                      <span className="input-label">
                        Front Facing Width (ft)
                      </span>
                      <input
                        className="input-field"
                        type="number"
                        placeholder="Front Facing Width (ft)"
                        name="plotWidth"
                        id="front-facing-width"
                        value={data.plotWidth}
                        onChange={this.handleChange}
                        required="required"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <Footer active="6" next="7" onBackClick={this.handleBackClick} />
            </form>
          </div>
          {/* Required */}
          <div id="step7" className="stepHidden">
            <form action="" onSubmit={this.handleStep7}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <div className="plot_floor_plan">
                    <div className="floorPlan plot_floor">
                      <p>{data.plotWidth}</p>
                      {/* <img src={data.plotType === 'RESD' ? "assets/ResidentialHouseLength.gif" : "assets/CommercialLength.gif"} alt="" /> */}
                      <img
                        src={
                          data.plotType === "RESD"
                            ? "img/depth_gif.gif"
                            : "assets/CommercialWidth.gif"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      className="compassUpdatedDiv"
                      style={{ left: "-20px", top: "50px" }}
                    >
                      <img
                        src="img/compass.svg"
                        alt=""
                        className="compassUpdated comapssImgGlobal"
                      />
                      <img src="img/triangle.svg" alt="" className="triangle" />
                    </div>
                  </div>
                  <div className="roadDiv mt-2">
                    <img
                      src="img/Group 3178.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="formInput mt-2">
                    <label className="input">
                      <span className="input-label">Depth (ft)</span>
                      <input
                        className="input-field"
                        type="number"
                        placeholder="Depth (ft)"
                        name="plotLength"
                        id="depth"
                        value={data.plotLength}
                        onChange={this.handleChange}
                        required="required"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <Footer active="7" next="21" onBackClick={this.handleBackClick} />
            </form>
          </div>

          <div id="step21" className="stepHidden">
            <form action="" onSubmit={this.handleStep21}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              {/* new content */}
              <div id="content">
                <div>
                  <div className="plot_floor_plan">
                    <div className="arrow-btn-right">
                      <img src="img/arrow.gif" alt="" />
                    </div>
                    <div className="floorPlan">
                      {/* <img src={data.plotType === 'RESD' ? "assets/ResidentialHouseLength.gif" : "assets/CommercialLength.gif"} alt="" /> */}
                      <img
                        src={
                          data.plotType === "RESD"
                            ? "img/new-plot-img.png"
                            : "assets/CommercialWidth.gif"
                        }
                        alt=""
                      />
                    </div>
                    <div className="compassUpdatedDiv">
                      <img
                        src="img/compass.svg"
                        alt=""
                        className="compassUpdated comapssImgGlobal"
                      />
                      <img src="img/triangle.svg" alt="" className="triangle" />
                    </div>
                  </div>
                  <div className="roadDiv mt-2">
                    <img
                      src="img/Group 3178.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mb-1 mt-2">
                    <b>Select What is in your Right side of plot</b>
                  </p>
                  <label className="radio_btns" htmlFor="right_neighour">
                    <img src="img/002-house.png" />
                    <label htmlFor="right_neighour">
                      <input
                        type="radio"
                        className="radios"
                        name="rightDirection"
                        id="right_neighour"
                        value="Neighbour"
                        required="required"
                      />
                      <span>Neighbour</span>
                    </label>
                  </label>

                  <label className="radio_btns" htmlFor="right_road">
                    <img src="img/001-road.png" />
                    <label htmlFor="right_road">
                      <input
                        type="radio"
                        className="radios"
                        name="rightDirection"
                        value="Road"
                        id="right_road"
                        required="required"
                      />
                      <span>Road</span>
                    </label>
                  </label>
                </div>
              </div>

              {/* new content */}
              <Footer
                active="21"
                next="22"
                onBackClick={this.handleBackClick21}
              />
            </form>
          </div>

          <div id="step22" className="stepHidden">
            <form action="" onSubmit={this.handleStep22}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              {/* new content */}
              <div id="content">
                <div>
                  <div className="plot_floor_plan back-plot">
                    <div className="arrow-btn-back">
                      <img src="img/arrow.gif" alt="" />
                    </div>
                    <div className="floorPlan back-floor-plan">
                      <img
                        src={
                          data.plotType === "RESD"
                            ? "img/new-plot-img.png"
                            : "assets/CommercialWidth.gif"
                        }
                        alt=""
                      />
                    </div>
                    <div className="compassUpdatedDiv" style={{ top: "70px" }}>
                      <img
                        src="img/compass.svg"
                        alt=""
                        className="compassUpdated comapssImgGlobal"
                      />
                      <img src="img/triangle.svg" alt="" className="triangle" />
                    </div>
                  </div>
                  <div className="roadDiv mt-2">
                    <img
                      src="img/Group 3178.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mb-1 mt-2">
                    <b>Select What is in your BACK side of plot</b>
                  </p>
                  <label className="radio_btns" htmlFor="back_neighour">
                    <img src="img/002-house.png" />
                    <label htmlFor="back_neighour">
                      <input
                        type="radio"
                        className="radios"
                        name="backDirection"
                        id="back_neighour"
                        value="Neighbour"
                        required="required"
                      />
                      <span>Neighbour</span>
                    </label>
                  </label>

                  <label className="radio_btns" htmlFor="back_road">
                    <img src="img/001-road.png" />
                    <label htmlFor="back_road">
                      <input
                        type="radio"
                        className="radios"
                        name="backDirection"
                        id="back_road"
                        value="Road"
                        required="required"
                      />
                      <span>Road</span>
                    </label>
                  </label>
                </div>
              </div>

              {/* new content */}
              <Footer
                active="22"
                next="23"
                onBackClick={this.handleBackClick}
              />
            </form>
          </div>

          <div id="step23" className="stepHidden">
            <form action="" onSubmit={this.handleStep23}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              {/* new content */}
              <div id="content">
                <div>
                  <div className="plot_floor_plan">
                    <div className="arrow-btn-left">
                      <img src="img/arrow.gif" alt="" />
                    </div>
                    <div className="floorPlan">
                      <img
                        src={
                          data.plotType === "RESD"
                            ? "img/new-plot-img.png"
                            : "assets/CommercialWidth.gif"
                        }
                        alt=""
                      />
                    </div>
                    <div className="compassUpdatedDiv">
                      <img
                        src="img/compass.svg"
                        alt=""
                        className="compassUpdated comapssImgGlobal"
                      />
                      <img src="img/triangle.svg" alt="" className="triangle" />
                    </div>
                  </div>
                  <div className="roadDiv mt-2">
                    <img
                      src="img/Group 3178.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p className="mb-1 mt-2">
                    <b>Select What is in your LEFT side of plot</b>
                  </p>
                  <label className="radio_btns" htmlFor="left_neighour">
                    <img src="img/002-house.png" />
                    <label htmlFor="left_neighour">
                      <input
                        type="radio"
                        className="radios"
                        name="leftDirection"
                        id="left_neighour"
                        value="Neighbour"
                        required="required"
                      />
                      <span>Neighbour</span>
                    </label>
                  </label>

                  <label className="radio_btns" htmlFor="left_road">
                    <img src="img/001-road.png" />
                    <label htmlFor="left_road">
                      <input
                        type="radio"
                        className="radios"
                        name="leftDirection"
                        id="left_road"
                        value="Road"
                        required="required"
                      />
                      <span>Road</span>
                    </label>
                  </label>
                </div>
              </div>

              {/* new content */}
              <Footer
                active="23"
                next="24"
                onBackClick={this.handleBackClick}
              />
            </form>
          </div>

          <div id="step24" className="stepHidden">
            <form action="" onSubmit={this.handleStep24}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              {/* new content */}
              <div id="content">
                <div>
                  <div className="plot_detail" style={{ position: "relative" }}>
                    <div className="floorPlan_detail">
                      <div className="fin-back" style={{ marginTop: "20px" }}>
                        {data.inBack === "Road" ? (
                          <img src="img/001-road.png" width="35px" />
                        ) : (
                          <img src="img/002-house.png" width="35px" />
                        )}
                      </div>

                      <div className="in-one-row">
                        <b className="fin-left">
                          {data.inLeft === "Road" ? (
                            <img src="img/001-road.png" />
                          ) : (
                            <img src="img/002-house.png" />
                          )}
                        </b>
                        <img
                          className="final-plot-img"
                          src="img/new-plot-img.png"
                          alt=""
                        />

                        <b className="fin-right">
                          {data.inRight === "Road" ? (
                            <img src="img/001-road.png" />
                          ) : (
                            <img src="img/002-house.png" />
                          )}
                        </b>
                      </div>
                    </div>
                    <div className="compassUpdatedDiv" style={{ top: "76px" }}>
                      <img
                        src="img/compass.svg"
                        alt=""
                        className="compassUpdated comapssImgGlobal"
                      />
                      <img src="img/triangle.svg" alt="" className="triangle" />
                    </div>
                    <p className="final-width">
                      Width = {data.plotWidth}sqft | Depth ={data.plotLength}
                      sqft
                    </p>
                  </div>
                  <div className="roadDiv">
                    <img
                      src="img/Group 3178.svg"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mb-1 mt-2">
                    {this.state.isConfirmed === false ? (
                      <b>
                        Is Your Plot Diagram Correct ?{" "}
                        <span>
                          {" "}
                          <div className="radioinline d-flex ">
                            <div className=" mb-1">
                              <input
                                type="radio"
                                id="north"
                                name="plotDirections"
                                checked={this.state.checked}
                                value="N"
                                onChange={(event) => this.handleYes(event)}
                              />
                              <label htmlFor="north">Yes</label>
                            </div>
                            <div
                              className=" ml-2"
                              style={{ marginTop: "2px", marginLeft: "10px" }}
                            >
                              <div
                                className="reviewDiv d-flex"
                                id="inline-popups"
                              >
                                <a
                                  href="#review"
                                  data-effect="mfp-move-from-top"
                                  style={{
                                    color: "#6c6a4e",
                                  }}
                                  onClick={(event) => this.handleNo(event)}
                                >
                                  <input
                                    type="radio"
                                    id="north"
                                    checked={this.state.unchecked}
                                    name="plotDirections"
                                    value="N"
                                  />
                                  <label htmlFor="north">No</label>
                                </a>
                              </div>
                            </div>
                          </div>
                        </span>{" "}
                      </b>
                    ) : (
                      <b>
                        <p style={{ marginTop: 10 }}>
                          {" "}
                          Thank You for the confirmation !!
                        </p>{" "}
                      </b>
                    )}
                  </div>
                </div>
              </div>

              {/* new content */}
              {this.state.isConfirmed === true ? (
                <Footer
                  active="24"
                  next="8"
                  onBackClick={this.handleBackClick}
                />
              ) : (
                <footer>
                  <button
                    active="24"
                    next="8"
                    type="button"
                    className="btn-main btn-back"
                    onClick={() => this.handleBackClick24()}
                  ></button>

                  <button
                    type="submit"
                    className="btn-main nextstep"
                    data-active="step11"
                    data-next="step12"
                    disabled
                  >
                    Next
                  </button>
                </footer>
              )}
            </form>
          </div>

          <div id="step8" className="stepHidden">
            <form action="" onSubmit={this.handleStep8}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div className="text-center">
                  <h2>Total Built Up Area</h2>
                  <div className="formInput builtUpInput">
                    <input
                      type="number"
                      placeholder="9000"
                      name="plotBuiltUpArea"
                      max="9999999"
                      value={data.plotBuiltUpArea}
                      min={this.minimumBuiltUpArea}
                      onChange={this.handleChange}
                      required="required"
                    />
                  </div>
                  <div className="formInput">
                    <p
                      style={{
                        marginTop: 70,
                        fontSize: 15,
                      }}
                    >
                      {" "}
                      Note*: Total Built up area is multiplication of plot area
                      X no of floors. If you wish construct it in lesser size
                      you can edit approx built up area in the given field
                    </p>
                  </div>
                  <div className="formInput">
                    <label className="flex  w100">
                      <div className="flex flex-center-center">
                        <input
                          type="checkbox"
                          name="isGst"
                          onChange={(event) => {
                            let ans = "";
                            if (event.target.checked) {
                              ans = "Yes";
                            } else {
                              ans = "NO";
                            }
                            this.setState({ ...this.state, isGst: ans });
                          }}
                        />
                      </div>
                      <div
                        className="flex flex-center-center"
                        style={{ fontWeight: "600" }}
                      >
                        Do you want to add your gst details?
                      </div>
                    </label>
                  </div>
                  {this.state?.isGst === "Yes" && (
                    <>
                      <span
                        className="flex w100"
                        style={{ margin: "10px 0px 5px 0px" }}
                      >
                        GST Number
                      </span>
                      <div className="inputWithIcon mb-3">
                        <label className="input">
                          <input
                            className="input-field"
                            type="text"
                            placeholder="Please enter your gst no"
                            name="gstNo"
                            value={this.state?.gstNo}
                            onChange={(event) => {
                              this.setState({
                                ...this.state,
                                gstNo: event.target.value,
                              });
                            }}
                            required="required"
                          />
                        </label>
                      </div>

                      <span
                        className="flex w100"
                        style={{ margin: "0px 0px 5px 0px" }}
                      >
                        Business Name
                      </span>
                      <div className="inputWithIcon mb-3">
                        <label className="input">
                          <input
                            className="input-field"
                            type="text"
                            placeholder="Please enter your business name"
                            name="businessName"
                            value={this.state?.businessName}
                            onChange={(event) => {
                              this.setState({
                                ...this.state,
                                businessName: event.target.value,
                              });
                            }}
                            required="required"
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Footer
                active="8"
                next="9"
                disabled={
                  this.state?.isGst === "Yes"
                    ? this.state?.gstNo === "" ||
                      this.state?.businessName === ""
                    : false
                }
                onBackClick={this.handleBackClick}
              />
            </form>
          </div>
          <div id="step9" className="stepHidden">
            <form action="" onSubmit={this.handleStep9}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <p className="mb-2">
                    <b>Select Your Budget</b>
                  </p>
                  <div className="selectYourBudget">
                    <Slider
                      marks={this.marks}
                      id="myBudget"
                      onChange={(value) => this.setState({ budget: value })}
                      value={this.state.budget}
                      min={1000000}
                      max={10000000}
                      tooltipPlacement="bottom"
                      trackStyle={{ backgroundColor: "#3AB54A" }}
                    />
                    {/* <input
                                            id="ex22"
                                            type="text"
                                            data-provide="slider"
                                            data-slider-ticks="[1, 2, 3, 4, 5]"
                                            data-slider-ticks-labels='["10L", "30L", "50L", "70L", "1 Cr+"]'
                                            data-slider-min="1"
                                            data-slider-max="5"
                                            data-slider-step="1"
                                            data-slider-value="3"
                                            data-slider-tooltip="hide"
                                        /> */}
                  </div>
                  <p className="mb-2">
                    <b>
                      Selected Budget : <i>{this.state.budget}</i>
                    </b>
                  </p>
                  <div className="familyMembers mt-5 d-flex justify-space-between">
                    <div className="numberOfRooms d-flex align-center">
                      <div className="iconWithText d-flex align-center">
                        <img src="img/room.svg" alt="" /> No. of Rooms
                      </div>
                    </div>
                    <div className="quantityDiv">
                      <button
                        type="button"
                        onClick={() => this.handleDecrement("roomqunatity")}
                      >
                        <img src="img/minus.svg" alt="" />
                      </button>
                      <input
                        className="input-group-field"
                        type="number"
                        name="room"
                        value={
                          this.state.roomqunatity ? this.state.roomqunatity : 0
                        }
                        min="0"
                        id="roomqunatity"
                        readOnly
                        required="required"
                      />
                      <button
                        type="button"
                        onClick={() => this.handleIncrement("roomqunatity")}
                      >
                        <img src="img/plus.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="familyMembers mt-5 d-flex justify-space-between">
                    <div className="numberOfRooms d-flex align-center">
                      <div className="iconWithText d-flex align-center">
                        <img src="img/adults.svg" alt="" /> Adults
                      </div>
                    </div>
                    <div className="quantityDiv">
                      <button
                        type="button"
                        onClick={() => this.handleDecrement("adults")}
                      >
                        <img src="img/minus.svg" alt="" />
                      </button>
                      <input
                        className="input-group-field"
                        type="number"
                        name="adults"
                        value={this.state.adults ? this.state.adults : 0}
                        id="adults"
                        readOnly
                        required="required"
                      />
                      <button
                        type="button"
                        onClick={() => this.handleIncrement("adults")}
                      >
                        <img src="img/plus.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="familyMembers mt-5 d-flex justify-space-between">
                    <div className="numberOfRooms d-flex align-center">
                      <div className="iconWithText d-flex align-center">
                        <img src="img/children.svg" alt="" /> Children
                      </div>
                    </div>
                    <div className="quantityDiv">
                      <button
                        type="button"
                        onClick={() => this.handleDecrement("kids")}
                      >
                        <img src="img/minus.svg" alt="" />
                      </button>
                      <input
                        className="input-group-field"
                        type="number"
                        name="kids"
                        value={this.state.kids ? this.state.kids : 0}
                        id="kids"
                        readOnly
                        required="required"
                      />
                      <button
                        type="button"
                        onClick={() => this.handleIncrement("kids")}
                      >
                        <img src="img/plus.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Footer active="9" next="10" onBackClick={this.handleBackClick} />
            </form>
          </div>

          <Step10
            onClick={this.handleStep10}
            onBackClick={this.handleBackClick}
          />
          {/* Image delete */}
          <div id="step11" className="stepHidden">
            <form action="" onSubmit={(e) => this.handleStep11("next", e)}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <p className="mb-2">
                    <b>Site Plan</b>
                  </p>
                  <p className="mb-2">
                    <b>
                      {" "}
                      Upload Govt authorized site plan (you may find it in plot
                      registry)
                    </b>
                  </p>
                  <input type="file" name="siteSketch" id="file-design" />
                </div>
              </div>
              <footer>
                <button
                  type="button"
                  className="btn-main btn-back"
                  onClick={() => this.handleBackClick(11)}
                ></button>
                <button
                  type="button"
                  className="btn-skip"
                  data-active="step11"
                  data-next="step12"
                  onClick={(e) => this.handleStep11("skip", e)}
                >
                  Skip
                </button>
                <button
                  type="submit"
                  className="btn-main nextstep"
                  data-active="step11"
                  data-next="step12"
                  onClick={(e) => this.handleStep11("next", e)}
                >
                  next
                </button>
              </footer>
            </form>
          </div>
          <div id="step12" className="stepHidden">
            <form action="" onSubmit={(e) => this.handleStep12("next", e)}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content" style={{ overflowY: "auto" }}>
                <Projects projects={this.state.projects} />
              </div>
              <div>
                <p style={{ marginLeft: 20 }} className="mb-2">
                  <b>
                    Skip if you dont find any of them suitable. we will contact
                    you more choice.
                  </b>
                </p>
              </div>
              <footer>
                <button
                  type="button"
                  className="btn-main btn-back"
                  onClick={() => this.handleBackClick(12)}
                ></button>
                <button
                  type="button"
                  className="btn-skip"
                  data-active="step12"
                  data-next="step13"
                  onClick={(e) => this.handleStep12("skip", e)}
                >
                  Skip
                </button>
                <button
                  type="submit"
                  className="btn-main nextstep"
                  data-active="step12"
                  data-next="step13"
                >
                  next
                </button>
              </footer>
            </form>
          </div>

          <div id="step13" className="stepHidden">
            <form action="" onSubmit={this.handleStep13}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content" className="packageContent">
                <Products
                  products={this.state.products}
                  onChange={this.handleProductChange}
                />
              </div>
              <footer>
                <button
                  type="button"
                  className="btn-main btn-back"
                  onClick={() => this.handleBackClick(13)}
                ></button>
                <button
                  type="submit"
                  className="btn-main nextstep"
                  data-active="step13"
                  data-next="step14"
                >
                  next
                </button>
              </footer>
            </form>
          </div>
          <div id="step14" className="stepHidden">
            <form action="" onSubmit={this.handleStep14}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content" className="packageContent">
                <div>
                  {selectedProduct.name && (
                    <div className="productName">
                      <p>{selectedProduct.name}</p>
                    </div>
                  )}

                  <p className="mb-2">
                    <b>Select Additional Modules</b>
                  </p>
                  <Addons
                    selectedProduct={selectedProduct}
                    addons={this.state.addons}
                  />
                  <div className="mb-5"></div>
                  <div className="hSeperator"></div>
                  {selectedProduct.pricing &&
                    selectedProduct.pricing.immediate_delivery !== -1 && (
                      <div className="addonBox mb-2 mt-2">
                        <div className="addonBoxInput">
                          <input
                            type="checkbox"
                            name="urgentDelivery"
                            id="urgentDelivery"
                            value="Need Within 24 Hours"
                            onChange={this.handleCheckboxChange}
                            checked={data.urgentDelivery}
                          />
                          <label
                            htmlFor="urgentDelivery"
                            className="addonLabel"
                          >
                            <div className="addonName">
                              <img src="img/Path 3311.svg" /> Need Within 24
                              Hours
                            </div>
                            <div className="priceAddon">
                              +₹
                              {this.state.products.length > 0
                                ? selectedProduct.pricing.immediate_delivery
                                : selectedProduct.pricing.instantDelivery}
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                  {selectedProduct.pricing &&
                    selectedProduct.pricing.extra_option !== -1 && (
                      <div className="addonBox mb-2 mt-2">
                        <div className="addonBoxInput">
                          <input
                            type="checkbox"
                            name="extraOption"
                            id="extraOption"
                            value="Need Extra Option"
                            onChange={this.handleCheckboxChange}
                            checked={data.extraOption}
                          />
                          <label htmlFor="extraOption" className="addonLabel">
                            <div className="addonName">
                              <img src="img/pluscircle.svg" /> Need Extra Option
                            </div>
                            <div className="priceAddon">
                              +₹
                              {this.state.products.length > 0
                                ? selectedProduct.pricing.extra_option
                                : selectedProduct.pricing.additionalOption}
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <footer>
                <button
                  type="button"
                  className="btn-main btn-back"
                  onClick={() => this.handleBackClick(14)}
                ></button>
                <button
                  type="submit"
                  className="btn-main "
                  data-active="step14"
                  data-next="step15"
                >
                  next
                </button>
              </footer>
            </form>
          </div>

          <div id="step15" className="stepHidden">
            <form action="" onSubmit={this.handleStep15}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content">
                <div>
                  <div className="inputWithIcon mb-3">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.162"
                        height="18"
                        viewBox="0 0 16.162 20.002"
                      >
                        <g id="placeholder" transform="translate(-140.528)">
                          <path
                            id="Path_529"
                            data-name="Path 529"
                            d="M148.609,20s8.081-6.135,8.081-11.921a8.081,8.081,0,1,0-16.162,0C140.528,13.867,148.609,20,148.609,20ZM144.3,8.263a4.313,4.313,0,1,1,4.313,4.313A4.318,4.318,0,0,1,144.3,8.263Z"
                            fill="#505050"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="2.218"
                            cy="2.218"
                            r="2.218"
                            transform="translate(145.569 9.045) rotate(-59.331)"
                            fill="#505050"
                          />
                        </g>
                      </svg>
                    </i>
                    <label className="input">
                      <span className="input-label">Address</span>
                      <input
                        type="text"
                        placeholder="Address"
                        name="locationaddress"
                        className="input-field"
                        value={data.locationaddress}
                        onChange={this.handleChange}
                        required="required"
                      />
                    </label>
                  </div>
                  <div className="inputWithIcon mb-3">
                    <select
                      id="country"
                      name="country"
                      value={data.country}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.getStates(e.target.value);
                      }}
                    >
                      {this.state.countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="inputWithIcon mb-3">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.162"
                        height="18"
                        viewBox="0 0 16.162 20.002"
                      >
                        <g id="placeholder" transform="translate(-140.528)">
                          <path
                            id="Path_529"
                            data-name="Path 529"
                            d="M148.609,20s8.081-6.135,8.081-11.921a8.081,8.081,0,1,0-16.162,0C140.528,13.867,148.609,20,148.609,20ZM144.3,8.263a4.313,4.313,0,1,1,4.313,4.313A4.318,4.318,0,0,1,144.3,8.263Z"
                            fill="#505050"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="2.218"
                            cy="2.218"
                            r="2.218"
                            transform="translate(145.569 9.045) rotate(-59.331)"
                            fill="#505050"
                          />
                        </g>
                      </svg>
                    </i>
                    <label className="input">
                      <span className="input-label">City</span>
                      <input
                        type="text"
                        placeholder="City"
                        name="city"
                        className="input-field"
                        value={data.city}
                        onChange={this.handleChange}
                        required="required"
                      />
                    </label>
                  </div>
                  <div className="inputWithIcon mb-3">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.162"
                        height="18"
                        viewBox="0 0 16.162 20.002"
                      >
                        <g id="placeholder" transform="translate(-140.528)">
                          <path
                            id="Path_529"
                            data-name="Path 529"
                            d="M148.609,20s8.081-6.135,8.081-11.921a8.081,8.081,0,1,0-16.162,0C140.528,13.867,148.609,20,148.609,20ZM144.3,8.263a4.313,4.313,0,1,1,4.313,4.313A4.318,4.318,0,0,1,144.3,8.263Z"
                            fill="#505050"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="2.218"
                            cy="2.218"
                            r="2.218"
                            transform="translate(145.569 9.045) rotate(-59.331)"
                            fill="#505050"
                          />
                        </g>
                      </svg>
                    </i>
                    <label className="input">
                      <span className="input-label">Zip Code</span>
                      <input
                        type="text"
                        placeholder="Zip Code"
                        name="postalcode"
                        className="input-field"
                        required="required"
                        value={data.postalcode}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="inputWithIcon mb-3">
                    <select
                      id="state"
                      name="state"
                      value={data.state}
                      onChange={this.handleChange}
                    >
                      {this.state.states.map((item) => (
                        <option key={item.code} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <Footer
                active="15"
                next="16"
                onBackClick={this.handleBackClick}
              />
            </form>
          </div>
          <div id="step16" className="stepHidden">
            <form action="" id="makeMyHouseForm" onSubmit={this.handleStep16}>
              <header>
                <div>
                  <div
                    id="topBar"
                    className="d-flex justify-space-between align-center"
                  >
                    <img src="img/MMH Logo_White.svg" alt="" />
                    <div className="reviewDiv d-flex" id="inline-popups">
                      <a
                        href="#review"
                        data-effect="mfp-move-from-top"
                        style={{ color: "white" }}
                      >
                        <img src="img/edit.svg" alt="" className="mr-1" />{" "}
                        Review
                      </a>
                    </div>
                  </div>

                  <br />
                  <div
                    id="stepDiv"
                    className="d-flex justify-space-between align-center"
                  >
                    <div id="basicInfo" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faUserCircle} />
                        <p>Basic Info</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="plotDetails" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faHome} />
                        <p>Plot Details</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="product" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faBuilding} />
                        <p>Product</p>
                      </div>
                    </div>
                    <div className="seperator"></div>
                    <div id="checkout" className="active">
                      <div className="stepIcon text-center">
                        <FontAwesomeIcon icon={faShoppingCart} />
                        <p>Checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div id="content" style={{ overflowY: "auto" }}>
                {selectedProducts?.length > 0 && (
                  <div
                    className="floorPlanDesignMain"
                    style={{ overflowY: "none" }}
                  >
                    <div className="sPackageBox mb-1">
                      <div className="sPackageHeading">
                        {selectedProduct.name}
                      </div>
                      <div className="sPackagePrice">
                        ₹ 
                        {selectedProduct.pricing?.basePrice -
                          selectedProduct.pricing?.additionalOption -
                          selectedProduct.pricing?.instantDelivery}
                        /-
                      </div>
                    </div>
                    {data.urgentDelivery && (
                      <div className="sUrgentDelivery mb-1">
                        <div className="sUrgentDelHeading">
                          <img
                            src="img/lighting (2).svg"
                            alt=""
                            height="20px"
                          />{" "}
                          Need Within 24 Hours
                        </div>
                        <div className="sUrgentDelPrice">
                          ₹ 
                          {selectedProduct.pricing.instantDelivery}
                          /-
                        </div>
                      </div>
                    )}
                    {data.extraOption && (
                      <div className="sExtraAddon mb-1">
                        <div className="sExtraAddonHeading">
                          <img src="img/pluscircle.svg" alt="" height="20px" />{" "}
                          Need Within 24 Hours
                        </div>
                        <div className="sExtraAddonPrice">
                          ₹ 
                          {selectedProduct.pricing.additionalOption}
                          /-
                        </div>
                      </div>
                    )}

                    <div className="sAddonBox">
                      {this.state.selectedProducts
                        .slice(1)
                        .map((selectedAddon) => (
                          <div
                            className="sAddonBoxInner mb-1"
                            key={selectedAddon.id}
                          >
                            <div className="sAddonHeading">
                              {selectedAddon.name}
                            </div>
                            <div className="sAddonPrice">
                              ₹ 
                              {selectedAddon.pricing.basePrice -
                                selectedAddon.pricing.additionalOption -
                                selectedAddon.pricing.instantDelivery}
                              /-
                            </div>
                          </div>
                        ))}
                      <div className="hSeperator my-4"></div>
                      <div className="promoCodeDiv mb-3">
                        <div className="d-flex">
                          <label className="input">
                            <span className="input-label">Promo Code</span>

                            <input
                              type="text"
                              name="promoCode"
                              className="input-field promoCode"
                              placeholder="Promo Code"
                              id="promo-code"
                              value={
                                localStorage.getItem("couponsDetail") &&
                                data.promoCode == ""
                                  ? JSON.parse(
                                      localStorage.getItem("couponsDetail")
                                    )[0]?.code
                                  : data.promoCode
                              }
                              onChange={this.handleChange}
                              onPaste={(e) => {
                                this.handlePromoClick(
                                  e.clipboardData.getData("Text")
                                );
                              }}
                              onKeyDown={this.handleKeyDown}
                            />
                          </label>
                          {this?.state?.promoDiscount > 0 && (
                            <div className="p-3">
                              <a href="#" onClick={this.handlePromoRemoveClick}>
                                <FontAwesomeIcon icon={faTimes} />
                              </a>
                            </div>
                          )}
                        </div>

                        <SpecialOffer
                          coupons={this.state.coupons}
                          onClick={this.handlePromoClick}
                          onClose={() => {
                            this.setState({
                              specialOfferShow: false,
                            });
                          }}
                          specialOfferShow={this.state.specialOfferShow}
                          onShowClick={() => {
                            this.getCoupons();
                          }}
                        />
                      </div>
                      {/* <div className="referalCodeDiv">
                                                <input
                                                    type="text"
                                                    className="referalCode"
                                                    name="refralCode"
                                                />
                                            </div> */}
                      {localStorage.getItem("couponsDetail") &&
                        JSON.parse(localStorage.getItem("couponsDetail"))[0]
                          ?.code == "PHDPTV" && (
                          <div className="christmas-gift-details">
                            <span style={{ width: "100%" }}>
                              {/* <span
                              style={{ fontWeight: "600", fontSize: "15px" }}
                            >
                              Christmas Gift:
                            </span> */}
                              <p>
                                {
                                  JSON.parse(
                                    localStorage.getItem("couponsDetail")
                                  )[0].description
                                }
                              </p>
                            </span>
                            <span style={{ height: "15rem", width: "100%" }}>
                            <img
                            //  src='https://api-uat.makemyhouse.com/public/Media/rimage/500/this.state.selectedProducts.coupons[0].image'
                            src={`http://makemyhouse.com/assets/themelibv2assets/app_assets/images/new/${ JSON.parse(
                              localStorage.getItem("couponsDetail")
                            )[0].image}`}                   
                           
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                            </span>
                          </div>
                        )}

                      <div className="pricingDiv mt-4">
                        <div className="priceDetail">
                          <b>Price Details</b>
                        </div>
                        <div className="priceBox mt-2 mb-1">
                          <div className="priceName">Gross Amount</div>
                          <div className="priceValue">
                            ₹ {this.state.order.pricing.grossAmount}/-
                          </div>
                        </div>

                        <div className="priceBox mb-1">
                          <div className="priceName">GST Amount</div>
                          <div className="priceValue">
                            ₹ {this.state.order.pricing.gstAmount}/-
                          </div>
                        </div>

                        {this.state.promoDiscount > 0 && (
                          <div className="priceBox promoPrice mb-1">
                            <div className="priceName">Promo Discount</div>
                            <div className="priceValue">
                              - ₹ {this.state.promoDiscount}
                              /-
                            </div>
                          </div>
                        )}

                        <div className="priceBox totalPrice mb-1">
                          <div className="priceName">Total</div>
                          <div className="priceValue">
                            ₹ 
                            {this.state.totalPrice}
                            /-
                          </div>
                        </div>
                        <div className="termsCondtions">
                          <input
                            type="checkbox"
                            id="termsCondtionsInput"
                            name="termsConditions"
                            value="I accept all the Terms & Conditions"
                          />{" "}
                          <label htmlFor="termsCondtionsInput">
                            I accept all the Terms & Conditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <footer>
                <button
                  type="button"
                  className="btn-main btn-back"
                  onClick={() => this.handleBackClick16()}
                ></button>
                <button type="submit" className="btn-main btn-pay">
                  Pay Now
                </button>
              </footer>
            </form>
          </div>
          <Review
            userDetails={this.state.userDetails}
            data={this.state.data}
            orderId={this.state.orderId}
            relatedDesigns={this.state.relatedDesigns}
            selectedProduct={selectedProduct}
          />
          <div id="service" className="white-popup mfp-with-anim mfp-hide">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
            accumsan porta lobortis. Donec aliquet lectus vitae urna venenatis,
            nec pulvinar diam vestibulum. Morbi rhoncus sem diam, at sagittis
            enim sollicitudin vitae. Suspendisse eu sollicitudin augue.
            Suspendisse at lacus sed massa mattis egestas eu eget turpis. Proin
            nec interdum lorem. Nullam congue lectus ac libero consequat
            pulvinar. Duis nec viverra ante. Cras non accumsan dolor. In dapibus
            molestie porttitor. Pellentesque porta auctor rutrum. Aenean nulla
            tortor, fermentum a egestas ac, sollicitudin vel diam.
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(App);
