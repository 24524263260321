import React from "react";

const MyRadio = ({checked, id, imgSrc, label, name, value, onChange}) => {
    return (
        <div className="radioBox">
            <input
                type="radio"
                value={value}
                id={id}
                name={name}
                checked={checked}
                required="required"
                onChange={onChange}
            />
            <label htmlFor={id}>
                <img src={imgSrc} alt="" />
                {label}
            </label>
        </div>
    );
};

export default MyRadio;
