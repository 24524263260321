import React from "react";

const Projects = ({ projects }) => {
  return (
    <div className="floorPlanDesignMain" style={{overflow:"none"}}>
      <div>
        <p className="mb-2">
          <b>
            Select any of the front design of your choice & click on next. ( It
            helps architect to get your choice before designing a plan)
          </b>
        </p>
      </div>
      {projects.map((project, index) => (
        <label className="floorPlanDesign mb-2" key={index} htmlFor={`designCode${index + 1}`} >
          <div className="floorPlanInputImage">
            <img src={project.image.imgUrl} alt="" />
          </div>
          <div className="floorPlanInput">
            <input
              type="checkbox"
              id={`designCode${index + 1}`}
              name="designCode[]"
              value={project.projectID}
              
            />
            <label htmlFor={`designCode${index + 1}`}>
              <b>Code:</b> {project.h1Content.h1Title}
            </label>
          </div>
        </label>
      ))}
      {projects.length === 0 && (
        <div className="floorPlanDesign mb-5">
          <div className="floorPlanInput mt-2">
            <h3>No design found</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
