import React, {useState, useEffect} from "react";
import "./intermediate.scss";

const IntermediateStep = (props) => {
    const [data,setData] = useState({})

    useEffect(()=>{
        if(props.data !== undefined && props.data.length > 0){
            setData((prev)=>{
                let d = JSON.parse(JSON.stringify(props.data[0]))
                prev = JSON.parse(JSON.stringify(prev))
                prev["Order Code"] = d.orderCode
                prev["Plot Details"] = {
                    "Direction":d.plot.direction,
                    "Width":d.plot.width,
                    "Length":d.plot.length,
                    "Area":d.plot.builtUpArea,
                    "Floors":d.plot.floors,
                }
                return prev
            })
    
        }
        
    },[props.data])


    return (
    <>
        <header style={{height:"fit-content"}}>
            <div>
        <div id="topBar" className="d-flex justify-space-between align-center">
            <img src="img/MMH Logo_White.svg" alt="" />
            <div className="reviewDiv d-flex" id="inline-popups">
            
            </div>
            
        </div>
        {/* <div className="banner" style={{
                                        minHeight: "5rem",
                                        padding:"2px 0",
                                        width: "100%",}}>
                                <img 
                                style={{
                                    height:"100%",
                                    minHeight: "5rem",
                                    width:"100%",
                                    objectFit: "cover",
                                }}
                                alt=""
                                src={"./img/xmas-banner-gift2.gif"}/>
                                </div>
                                <br/> */}
                                </div>
        </header>
        <div className="flex flex-center-center font-large font-w-large w100" style={{paddingTop:"20px"}}>
            You already have a draft order
        </div>
        <div className="flex flex-center-center intermediate-step">
        {Object.keys(data).length > 0 && Object.entries(data).map(([k,v], i)=>(<>
            
            {k !== "Plot Details" &&  <div className="flex flex-space-bw intermediate-container">
                <div className="flex flex-center-center font-small">{k}:</div>
                <div className="flex flex-center-center font-small">{v}</div>
            </div>}{k === "Plot Details" && Object.entries(data[k]).map(([pk,pv])=>(
                <div className="flex flex-space-bw intermediate-container">
                <div className="flex flex-center-center font-small">{pk}:</div>
                <div className="flex flex-center-center font-small">{pv}</div>
            </div>
            ))}
        
        </>))}</div>
        <footer className="inter-foot w100">
        <div className="flex flex-space-bw flex-wrap w100 ">
            <div
                className="button-container flex flex-center-center font-small"
                onClick={() => props?.handleIntermediateStep("any")}
            >
                Resume Order
            </div>
            <div
                className="button-container flex flex-center-center font-small"
                onClick={() => props?.handleIntermediateStep("new")}
            >
                Create New One
            </div>
            </div>
        </footer>
    </>
    );
};

export default IntermediateStep;
