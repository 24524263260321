import React from "react";

const Review = (props) => {
    return (
        <div id="review" className="white-popup mfp-with-anim mfp-hide">
            <div className="editBox mb-2">
                <div className="editBoxText" id="text-first-name">
                    {props?.userDetails?.firstname}
                </div>
                <div className="editBoxIcon" data-step="step3">
                    <img src="img/editIcon.svg" alt="" />
                </div>
            </div>
            <div className="editBox mb-2">
                <div className="editBoxText" id="text-email">
                    {props?.userDetails?.email}
                </div>
                <div className="editBoxIcon" data-step="step3">
                    <img src="img/editIcon.svg" alt="" />
                </div>
            </div>
            <div className="hSeperator"></div>

            {props.data.plotType && (
                <div id="plot-type" className="editBox mb-2 mt-2">
                    <div className="editBoxText">
                        <b>Plot Type: </b>{" "}
                        <span className="ePlotType">
                            {props.data.plotType === "RESD"
                                ? "Residential "
                                : "Commercial "}{" "}
                        </span>{" "}
                        | <b>No. of Floors</b>{" "}
                        <span className="enof">{props.data.floors}</span>
                    </div>
                    {props.orderId === null && (
                        <div className="editBoxIcon" data-step="step4">
                            <img src="img/editIcon.svg" alt="" />
                        </div>
                    )}
                </div>
            )}

            {props.data.plotDirection && (
                <div id="eb-plot-direction" className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Plot Direction: </b>{" "}
                        <span className="ePlotDirection">
                            {props.data.plotDirection}
                        </span>
                    </div>
                    {props.orderId === null && (
                        <div className="editBoxIcon" data-step="step5">
                            <img src="img/editIcon.svg" alt="" />
                        </div>
                    )}
                </div>
            )}

            {props.data.plotWidth && (
                <div id="eb-front-facing-width" className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Front Facing Width: </b>{" "}
                        <span className="eFrontFacing">
                            {props.data.plotWidth}
                        </span>
                    </div>
                    {props.orderId === null && (
                        <div className="editBoxIcon" data-step="step6">
                            <img src="img/editIcon.svg" alt="" />
                        </div>
                    )}
                </div>
            )}

            {props.data.plotLength && (
                <div id="eb-depth" className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Depth: </b>{" "}
                        <span className="eDepth">{props.data.plotLength}</span>
                    </div>
                    {props.orderId === null && (
                        <div className="editBoxIcon" data-step="step7">
                            <img src="img/editIcon.svg" alt="" />
                        </div>
                    )}
                </div>
            )}
            {props.data.inRight && (
                <div id="eb-depth" className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Location Right : </b>{" "}
                        <span className="eDepth">{props.data.inRight}</span>
                    </div>
                    <div className="editBoxIcon" data-step="step21">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}
            {props.data.inRight && (
                <div id="eb-depth" className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Location Back : </b>{" "}
                        <span className="eDepth">{props.data.inBack}</span>
                    </div>
                    <div className="editBoxIcon" data-step="step22">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}
            {props.data.inRight && (
                <div id="eb-depth" className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Location Left : </b>{" "}
                        <span className="eDepth">{props.data.inLeft}</span>
                    </div>
                    <div className="editBoxIcon" data-step="step23">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}

            {props.data.budget && (
                <div className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Budget: </b>{" "}
                        <span className="eBudget">{props.data.budget} </span> |{" "}
                        <b>No. of Rooms</b>{" "}
                        <span className="enor">{props.data.numberOfRooms}</span>
                        <br />
                        <b>Members: </b>{" "}
                        <span className="eMembersAdult">
                            {props.data.adults}x Adults
                        </span>{" "}
                        |{" "}
                        <span className="eMembersKids">
                            {props.data.children}x Kids
                        </span>
                    </div>
                    <div className="editBoxIcon" data-step="step9">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}

            {props.data.budget && (
                <div className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Location : </b>{" "}
                        <span className="eBudget">N - Road </span>
                        <span className="eBudget">S - Neighbour </span>
                        <span className="eBudget">E - Road </span>
                        <span className="eBudget">W - Neighbour </span>
                    </div>
                    <div className="editBoxIcon" data-step="step9">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}

            {props.relatedDesigns.length !== 0 && (
                <div className="editBox mb-2">
                    <div className="editBoxText">
                        <b>Designs: </b>{" "}
                        <span className="eDesign">
                            {props.relatedDesigns.join(", ")}
                        </span>
                    </div>
                    <div className="editBoxIcon" data-step="step12">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}

            <div className="hSeperator"></div>

            {props.selectedProduct.name && (
                <div className="editBox mb-2 mt-2">
                    <div className="editBoxText">
                        <b>Package: </b>{" "}
                        <span className="ePackage">
                            {props.selectedProduct.name}
                        </span>
                    </div>
                    <div className="editBoxIcon" data-step="step13">
                        <img src="img/editIcon.svg" alt="" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Review;
